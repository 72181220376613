import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CardReaderHrm from './card-reader-hrm';
import CardReaderHrmDetail from './card-reader-hrm-detail';
import CardReaderHrmUpdate from './card-reader-hrm-update';
import CardReaderHrmDeleteDialog from './card-reader-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CardReaderHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CardReaderHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CardReaderHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={CardReaderHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CardReaderHrmDeleteDialog} />
  </>
);

export default Routes;
