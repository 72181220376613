import { Moment } from 'moment';

export interface IClaim {
  id?: number;
  referenceNo?: string;
  claimDate?: Moment;
  totalAmount?: number;
  description?: string;
  approveDate?: Moment;
  disperseDate?: Moment;
  employeeLegalName?: string;
  employeeId?: number;
  claimTypeName?: string;
  claimTypeId?: number;
  claimStatusName?: string;
  claimStatusId?: number;
}

export const defaultValue: Readonly<IClaim> = {};
