export interface IPositionHrm {
  id?: number;
  title?: string;
  minSalary?: number;
  maxSalary?: number;
  description?: string;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<IPositionHrm> = {};
