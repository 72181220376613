import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './leave-entitlement-hrm.reducer';
import { ILeaveEntitlementHrm } from 'app/shared/model/leave-entitlement-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ILeaveEntitlementHrmDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class LeaveEntitlementHrmDetail extends React.Component<ILeaveEntitlementHrmDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { leaveEntitlementEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="acelsysHrmApp.leaveEntitlement.detail.title">LeaveEntitlement</Translate> [<b>
              {leaveEntitlementEntity.id}
            </b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="entitlementYear">
                <Translate contentKey="acelsysHrmApp.leaveEntitlement.entitlementYear">Entitlement Year</Translate>
              </span>
            </dt>
            <dd>{leaveEntitlementEntity.entitlementYear}</dd>
            <dt>
              <span id="entitlement">
                <Translate contentKey="acelsysHrmApp.leaveEntitlement.entitlement">Entitlement</Translate>
              </span>
            </dt>
            <dd>{leaveEntitlementEntity.entitlement}</dd>
            <dt>
              <span id="cfLastYear">
                <Translate contentKey="acelsysHrmApp.leaveEntitlement.cfLastYear">Cf Last Year</Translate>
              </span>
            </dt>
            <dd>{leaveEntitlementEntity.cfLastYear}</dd>
            <dt>
              <span id="applied">
                <Translate contentKey="acelsysHrmApp.leaveEntitlement.applied">Applied</Translate>
              </span>
            </dt>
            <dd>{leaveEntitlementEntity.applied}</dd>
            <dt>
              <span id="approved">
                <Translate contentKey="acelsysHrmApp.leaveEntitlement.approved">Approved</Translate>
              </span>
            </dt>
            <dd>{leaveEntitlementEntity.approved}</dd>
            <dt>
              <span id="rejected">
                <Translate contentKey="acelsysHrmApp.leaveEntitlement.rejected">Rejected</Translate>
              </span>
            </dt>
            <dd>{leaveEntitlementEntity.rejected}</dd>
            <dt>
              <span id="cancelled">
                <Translate contentKey="acelsysHrmApp.leaveEntitlement.cancelled">Cancelled</Translate>
              </span>
            </dt>
            <dd>{leaveEntitlementEntity.cancelled}</dd>
            <dt>
              <Translate contentKey="acelsysHrmApp.leaveEntitlement.leaveType">Leave Type</Translate>
            </dt>
            <dd>{leaveEntitlementEntity.leaveTypeName ? leaveEntitlementEntity.leaveTypeName : ''}</dd>
            <dt>
              <Translate contentKey="acelsysHrmApp.leaveEntitlement.employee">Employee</Translate>
            </dt>
            <dd>{leaveEntitlementEntity.employeeLegalName ? leaveEntitlementEntity.employeeLegalName : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/leave-entitlement-hrm" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>&nbsp;
          <Button tag={Link} to={`/entity/leave-entitlement-hrm/${leaveEntitlementEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ leaveEntitlement }: IRootState) => ({
  leaveEntitlementEntity: leaveEntitlement.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveEntitlementHrmDetail);
