import React from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from '../header-components';

export const EntitiesMenu = props => (
  // tslint:disable-next-line:jsx-self-close
  <NavDropdown icon="th-list" name={translate('global.menu.entities.main')} id="entity-menu">
    <DropdownItem tag={Link} to="/entity/department-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.departmentHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/employee-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.employeeHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/access-card-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.accessCardHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/position-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.positionHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/job-description-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.jobDescriptionHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/festival-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.festivalHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/leave-application-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.leaveApplicationHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/leave-status-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.leaveStatusHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/leave-type-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.leaveTypeHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/leave-entitlement-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.leaveEntitlementHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/attendance-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.attendanceHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/weekly-attendance-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.weeklyAttendanceHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/source-document-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.sourceDocumentHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/room-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.roomHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/door-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.doorHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/card-reader-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.cardReaderHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/door-access-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.doorAccessHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/out-of-office-report-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.outOfOfficeReportHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/contact-type-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.contactTypeHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/contact-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.contactHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/address-type-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.addressTypeHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/employee-address-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.employeeAddressHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/company-address-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.companyAddressHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/company-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.companyHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/company-type-hrm">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.companyTypeHrm" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/leave-applied">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.leaveApplied" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/claim-type">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.claimType" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/claim">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.claim" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/claim-status">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.claimStatus" />
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/claim-item">
      <FontAwesomeIcon icon="asterisk" fixedWidth />&nbsp;<Translate contentKey="global.menu.entities.claimItem" />
    </DropdownItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
