export interface IEmployeeAddressHrm {
  id?: number;
  addr1?: string;
  addr2?: string;
  addr3?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<IEmployeeAddressHrm> = {};
