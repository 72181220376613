import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ContactTypeHrm from './contact-type-hrm';
import ContactTypeHrmDetail from './contact-type-hrm-detail';
import ContactTypeHrmUpdate from './contact-type-hrm-update';
import ContactTypeHrmDeleteDialog from './contact-type-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ContactTypeHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ContactTypeHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ContactTypeHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={ContactTypeHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ContactTypeHrmDeleteDialog} />
  </>
);

export default Routes;
