import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeaveApplied from './leave-applied';
import LeaveAppliedDetail from './leave-applied-detail';
import LeaveAppliedUpdate from './leave-applied-update';
import LeaveAppliedDeleteDialog from './leave-applied-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeaveAppliedUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeaveAppliedUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeaveAppliedDetail} />
      <ErrorBoundaryRoute path={match.url} component={LeaveApplied} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={LeaveAppliedDeleteDialog} />
  </>
);

export default Routes;
