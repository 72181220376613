import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IDoorHrm } from 'app/shared/model/door-hrm.model';
import { getEntities as getDoors } from 'app/entities/door-hrm/door-hrm.reducer';
import { IEmployeeHrm } from 'app/shared/model/employee-hrm.model';
import { getEntities as getEmployees } from 'app/entities/employee-hrm/employee-hrm.reducer';
import { getEntity, updateEntity, createEntity, reset } from './door-access-hrm.reducer';
import { IDoorAccessHrm } from 'app/shared/model/door-access-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IDoorAccessHrmUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IDoorAccessHrmUpdateState {
  isNew: boolean;
  doorId: string;
  employeeId: string;
}

export class DoorAccessHrmUpdate extends React.Component<IDoorAccessHrmUpdateProps, IDoorAccessHrmUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      doorId: '0',
      employeeId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getDoors();
    this.props.getEmployees();
  }

  saveEntity = (event, errors, values) => {
    values.accessDateTime = new Date(values.accessDateTime);
    values.processDateTime = new Date(values.processDateTime);

    if (errors.length === 0) {
      const { doorAccessEntity } = this.props;
      const entity = {
        ...doorAccessEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/door-access-hrm');
  };

  render() {
    const { doorAccessEntity, doors, employees, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="acelsysHrmApp.doorAccess.home.createOrEditLabel">
              <Translate contentKey="acelsysHrmApp.doorAccess.home.createOrEditLabel">Create or edit a DoorAccess</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : doorAccessEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="door-access-hrm-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="accessDateTimeLabel" for="accessDateTime">
                    <Translate contentKey="acelsysHrmApp.doorAccess.accessDateTime">Access Date Time</Translate>
                  </Label>
                  <AvInput
                    id="door-access-hrm-accessDateTime"
                    type="datetime-local"
                    className="form-control"
                    name="accessDateTime"
                    value={isNew ? null : convertDateTimeFromServer(this.props.doorAccessEntity.accessDateTime)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="outLabel" check>
                    <AvInput id="door-access-hrm-out" type="checkbox" className="form-control" name="out" />
                    <Translate contentKey="acelsysHrmApp.doorAccess.out">Out</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="inwardLabel" check>
                    <AvInput id="door-access-hrm-inward" type="checkbox" className="form-control" name="inward" />
                    <Translate contentKey="acelsysHrmApp.doorAccess.inward">Inward</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="processedLabel" check>
                    <AvInput id="door-access-hrm-processed" type="checkbox" className="form-control" name="processed" />
                    <Translate contentKey="acelsysHrmApp.doorAccess.processed">Processed</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="processDateTimeLabel" for="processDateTime">
                    <Translate contentKey="acelsysHrmApp.doorAccess.processDateTime">Process Date Time</Translate>
                  </Label>
                  <AvInput
                    id="door-access-hrm-processDateTime"
                    type="datetime-local"
                    className="form-control"
                    name="processDateTime"
                    value={isNew ? null : convertDateTimeFromServer(this.props.doorAccessEntity.processDateTime)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="door.name">
                    <Translate contentKey="acelsysHrmApp.doorAccess.door">Door</Translate>
                  </Label>
                  <AvInput id="door-access-hrm-door" type="select" className="form-control" name="doorId">
                    <option value="" key="0" />
                    {doors
                      ? doors.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="employee.legalName">
                    <Translate contentKey="acelsysHrmApp.doorAccess.employee">Employee</Translate>
                  </Label>
                  <AvInput id="door-access-hrm-employee" type="select" className="form-control" name="employeeId">
                    <option value="" key="0" />
                    {employees
                      ? employees.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.legalName}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/door-access-hrm" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />&nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />&nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  doors: storeState.door.entities,
  employees: storeState.employee.entities,
  doorAccessEntity: storeState.doorAccess.entity,
  loading: storeState.doorAccess.loading,
  updating: storeState.doorAccess.updating,
  updateSuccess: storeState.doorAccess.updateSuccess
});

const mapDispatchToProps = {
  getDoors,
  getEmployees,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoorAccessHrmUpdate);
