export interface IDoorHrm {
  id?: number;
  name?: string;
  location?: string;
  inUse?: boolean;
  roomName?: string;
  roomId?: number;
}

export const defaultValue: Readonly<IDoorHrm> = {
  inUse: false
};
