import { Moment } from 'moment';

export interface IClaimItem {
  id?: number;
  seq?: number;
  description?: string;
  quantity?: number;
  amount?: number;
  totalAmount?: number;
  transDate?: Moment;
  claimReferenceNo?: string;
  claimId?: number;
}

export const defaultValue: Readonly<IClaimItem> = {};
