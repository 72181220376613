import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeaveTypeHrm from './leave-type-hrm';
import LeaveTypeHrmDetail from './leave-type-hrm-detail';
import LeaveTypeHrmUpdate from './leave-type-hrm-update';
import LeaveTypeHrmDeleteDialog from './leave-type-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeaveTypeHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeaveTypeHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeaveTypeHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={LeaveTypeHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={LeaveTypeHrmDeleteDialog} />
  </>
);

export default Routes;
