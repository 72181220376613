import { Moment } from 'moment';

export interface IWeeklyAttendanceHrm {
  id?: number;
  calendarYear?: number;
  weekNo?: number;
  workHours?: number;
  calendarMonth?: number;
  createDateTime?: Moment;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<IWeeklyAttendanceHrm> = {};
