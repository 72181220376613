import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ILeaveTypeHrm } from 'app/shared/model/leave-type-hrm.model';
import { getEntities as getLeaveTypes } from 'app/entities/leave-type-hrm/leave-type-hrm.reducer';
import { IEmployeeHrm } from 'app/shared/model/employee-hrm.model';
import { getEntities as getEmployees } from 'app/entities/employee-hrm/employee-hrm.reducer';
import { ILeaveStatusHrm } from 'app/shared/model/leave-status-hrm.model';
import { getEntities as getLeaveStatuses } from 'app/entities/leave-status-hrm/leave-status-hrm.reducer';
import { getEntity, updateEntity, createEntity, reset } from './leave-application-hrm.reducer';
import { ILeaveApplicationHrm } from 'app/shared/model/leave-application-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ILeaveApplicationHrmUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ILeaveApplicationHrmUpdateState {
  isNew: boolean;
  leaveTypeId: string;
  employeeId: string;
  statusId: string;
}

export class LeaveApplicationHrmUpdate extends React.Component<ILeaveApplicationHrmUpdateProps, ILeaveApplicationHrmUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      leaveTypeId: '0',
      employeeId: '0',
      statusId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getLeaveTypes();
    this.props.getEmployees();
    this.props.getLeaveStatuses();
  }

  saveEntity = (event, errors, values) => {
    values.applyDate = new Date(values.applyDate);
    values.processedDate = new Date(values.processedDate);

    if (errors.length === 0) {
      const { leaveApplicationEntity } = this.props;
      const entity = {
        ...leaveApplicationEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/leave-application-hrm');
  };

  render() {
    const { leaveApplicationEntity, leaveTypes, employees, leaveStatuses, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="acelsysHrmApp.leaveApplication.home.createOrEditLabel">
              <Translate contentKey="acelsysHrmApp.leaveApplication.home.createOrEditLabel">Create or edit a LeaveApplication</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : leaveApplicationEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="leave-application-hrm-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="applyDateLabel" for="applyDate">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.applyDate">Apply Date</Translate>
                  </Label>
                  <AvInput
                    id="leave-application-hrm-applyDate"
                    type="datetime-local"
                    className="form-control"
                    name="applyDate"
                    value={isNew ? null : convertDateTimeFromServer(this.props.leaveApplicationEntity.applyDate)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="startDateLabel" for="startDate">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.startDate">Start Date</Translate>
                  </Label>
                  <AvField
                    id="leave-application-hrm-startDate"
                    type="date"
                    className="form-control"
                    name="startDate"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="endDateLabel" for="endDate">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.endDate">End Date</Translate>
                  </Label>
                  <AvField id="leave-application-hrm-endDate" type="date" className="form-control" name="endDate" />
                </AvGroup>
                <AvGroup>
                  <Label id="resumeWorkDateLabel" for="resumeWorkDate">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.resumeWorkDate">Resume Work Date</Translate>
                  </Label>
                  <AvField id="leave-application-hrm-resumeWorkDate" type="date" className="form-control" name="resumeWorkDate" />
                </AvGroup>
                <AvGroup>
                  <Label id="appliedDaysLabel" for="appliedDays">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.appliedDays">Applied Days</Translate>
                  </Label>
                  <AvField
                    id="leave-application-hrm-appliedDays"
                    type="string"
                    className="form-control"
                    name="appliedDays"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="emergencyContactLabel" for="emergencyContact">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.emergencyContact">Emergency Contact</Translate>
                  </Label>
                  <AvField
                    id="leave-application-hrm-emergencyContact"
                    type="text"
                    name="emergencyContact"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 30, errorMessage: translate('entity.validation.maxlength', { max: 30 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="processedDateLabel" for="processedDate">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.processedDate">Processed Date</Translate>
                  </Label>
                  <AvInput
                    id="leave-application-hrm-processedDate"
                    type="datetime-local"
                    className="form-control"
                    name="processedDate"
                    value={isNew ? null : convertDateTimeFromServer(this.props.leaveApplicationEntity.processedDate)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="approvedDaysLabel" for="approvedDays">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.approvedDays">Approved Days</Translate>
                  </Label>
                  <AvField id="leave-application-hrm-approvedDays" type="string" className="form-control" name="approvedDays" />
                </AvGroup>
                <AvGroup>
                  <Label id="reasonLabel" for="reason">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.reason">Reason</Translate>
                  </Label>
                  <AvField id="leave-application-hrm-reason" type="text" name="reason" />
                </AvGroup>
                <AvGroup>
                  <Label for="leaveType.name">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.leaveType">Leave Type</Translate>
                  </Label>
                  <AvInput id="leave-application-hrm-leaveType" type="select" className="form-control" name="leaveTypeId">
                    <option value="" key="0" />
                    {leaveTypes
                      ? leaveTypes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="employee.legalName">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.employee">Employee</Translate>
                  </Label>
                  <AvInput id="leave-application-hrm-employee" type="select" className="form-control" name="employeeId">
                    <option value="" key="0" />
                    {employees
                      ? employees.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.legalName}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="status.name">
                    <Translate contentKey="acelsysHrmApp.leaveApplication.status">Status</Translate>
                  </Label>
                  <AvInput id="leave-application-hrm-status" type="select" className="form-control" name="statusId">
                    <option value="" key="0" />
                    {leaveStatuses
                      ? leaveStatuses.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/leave-application-hrm" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />&nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />&nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  leaveTypes: storeState.leaveType.entities,
  employees: storeState.employee.entities,
  leaveStatuses: storeState.leaveStatus.entities,
  leaveApplicationEntity: storeState.leaveApplication.entity,
  loading: storeState.leaveApplication.loading,
  updating: storeState.leaveApplication.updating,
  updateSuccess: storeState.leaveApplication.updateSuccess
});

const mapDispatchToProps = {
  getLeaveTypes,
  getEmployees,
  getLeaveStatuses,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveApplicationHrmUpdate);
