import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeaveEntitlementHrm from './leave-entitlement-hrm';
import LeaveEntitlementHrmDetail from './leave-entitlement-hrm-detail';
import LeaveEntitlementHrmUpdate from './leave-entitlement-hrm-update';
import LeaveEntitlementHrmDeleteDialog from './leave-entitlement-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeaveEntitlementHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeaveEntitlementHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeaveEntitlementHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={LeaveEntitlementHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={LeaveEntitlementHrmDeleteDialog} />
  </>
);

export default Routes;
