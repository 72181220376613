import { Moment } from 'moment';

export interface IOutOfOfficeReportHrm {
  id?: number;
  reportDateTime?: Moment;
  location?: string;
  geoLocation?: string;
  notes?: string;
  inward?: boolean;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<IOutOfOfficeReportHrm> = {
  inward: false
};
