export interface ILeaveEntitlementHrm {
  id?: number;
  entitlementYear?: number;
  entitlement?: number;
  cfLastYear?: number;
  applied?: number;
  approved?: number;
  rejected?: number;
  cancelled?: number;
  leaveTypeName?: string;
  leaveTypeId?: number;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<ILeaveEntitlementHrm> = {};
