import { Moment } from 'moment';

export interface IEmployeeHrm {
  id?: number;
  firstName?: string;
  lastName?: string;
  legalName?: string;
  email?: string;
  phoneNumber?: string;
  hireDate?: Moment;
  nicNo?: string;
  oldIcNo?: string;
  passportNo?: string;
  epfNo?: string;
  incomeTaxNo?: string;
  employeeNo?: string;
  weeklyWorkHour?: number;
  userLogin?: string;
  userId?: number;
  companyName?: string;
  companyId?: number;
  managerId?: number;
  departmentName?: string;
  departmentId?: number;
}

export const defaultValue: Readonly<IEmployeeHrm> = {};
