import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DoorHrm from './door-hrm';
import DoorHrmDetail from './door-hrm-detail';
import DoorHrmUpdate from './door-hrm-update';
import DoorHrmDeleteDialog from './door-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={DoorHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={DoorHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DoorHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={DoorHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={DoorHrmDeleteDialog} />
  </>
);

export default Routes;
