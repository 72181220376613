import { Moment } from 'moment';

export interface ILeaveApplied {
  id?: number;
  reason?: string;
  leaveDate?: Moment;
  leaveApplicationId?: number;
  leaveStatusName?: string;
  leaveStatusId?: number;
  employeeLegalName?: string;
  employeeId?: number;
  leaveTypeName?: string;
  leaveTypeId?: number;
}

export const defaultValue: Readonly<ILeaveApplied> = {};
