export interface ICompanyAddressHrm {
  id?: number;
  addr1?: string;
  addr2?: string;
  addr3?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
  companyName?: string;
  companyId?: number;
}

export const defaultValue: Readonly<ICompanyAddressHrm> = {};
