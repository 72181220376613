import { Moment } from 'moment';

export interface IDoorAccessHrm {
  id?: number;
  accessDateTime?: Moment;
  out?: boolean;
  inward?: boolean;
  processed?: boolean;
  processDateTime?: Moment;
  doorName?: string;
  doorId?: number;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<IDoorAccessHrm> = {
  out: false,
  inward: false,
  processed: false
};
