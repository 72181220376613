import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import department, {
  DepartmentHrmState
} from 'app/entities/department-hrm/department-hrm.reducer';
// prettier-ignore
import employee, {
  EmployeeHrmState
} from 'app/entities/employee-hrm/employee-hrm.reducer';
// prettier-ignore
import accessCard, {
  AccessCardHrmState
} from 'app/entities/access-card-hrm/access-card-hrm.reducer';
// prettier-ignore
import position, {
  PositionHrmState
} from 'app/entities/position-hrm/position-hrm.reducer';
// prettier-ignore
import jobDescription, {
  JobDescriptionHrmState
} from 'app/entities/job-description-hrm/job-description-hrm.reducer';
// prettier-ignore
import festival, {
  FestivalHrmState
} from 'app/entities/festival-hrm/festival-hrm.reducer';
// prettier-ignore
import leaveApplication, {
  LeaveApplicationHrmState
} from 'app/entities/leave-application-hrm/leave-application-hrm.reducer';
// prettier-ignore
import leaveStatus, {
  LeaveStatusHrmState
} from 'app/entities/leave-status-hrm/leave-status-hrm.reducer';
// prettier-ignore
import leaveType, {
  LeaveTypeHrmState
} from 'app/entities/leave-type-hrm/leave-type-hrm.reducer';
// prettier-ignore
import leaveEntitlement, {
  LeaveEntitlementHrmState
} from 'app/entities/leave-entitlement-hrm/leave-entitlement-hrm.reducer';
// prettier-ignore
import attendance, {
  AttendanceHrmState
} from 'app/entities/attendance-hrm/attendance-hrm.reducer';
// prettier-ignore
import weeklyAttendance, {
  WeeklyAttendanceHrmState
} from 'app/entities/weekly-attendance-hrm/weekly-attendance-hrm.reducer';
// prettier-ignore
import sourceDocument, {
  SourceDocumentHrmState
} from 'app/entities/source-document-hrm/source-document-hrm.reducer';
// prettier-ignore
import room, {
  RoomHrmState
} from 'app/entities/room-hrm/room-hrm.reducer';
// prettier-ignore
import door, {
  DoorHrmState
} from 'app/entities/door-hrm/door-hrm.reducer';
// prettier-ignore
import cardReader, {
  CardReaderHrmState
} from 'app/entities/card-reader-hrm/card-reader-hrm.reducer';
// prettier-ignore
import doorAccess, {
  DoorAccessHrmState
} from 'app/entities/door-access-hrm/door-access-hrm.reducer';
// prettier-ignore
import outOfOfficeReport, {
  OutOfOfficeReportHrmState
} from 'app/entities/out-of-office-report-hrm/out-of-office-report-hrm.reducer';
// prettier-ignore
import contactType, {
  ContactTypeHrmState
} from 'app/entities/contact-type-hrm/contact-type-hrm.reducer';
// prettier-ignore
import contact, {
  ContactHrmState
} from 'app/entities/contact-hrm/contact-hrm.reducer';
// prettier-ignore
import addressType, {
  AddressTypeHrmState
} from 'app/entities/address-type-hrm/address-type-hrm.reducer';
// prettier-ignore
import employeeAddress, {
  EmployeeAddressHrmState
} from 'app/entities/employee-address-hrm/employee-address-hrm.reducer';
// prettier-ignore
import companyAddress, {
  CompanyAddressHrmState
} from 'app/entities/company-address-hrm/company-address-hrm.reducer';
// prettier-ignore
import company, {
  CompanyHrmState
} from 'app/entities/company-hrm/company-hrm.reducer';
// prettier-ignore
import companyType, {
  CompanyTypeHrmState
} from 'app/entities/company-type-hrm/company-type-hrm.reducer';
// prettier-ignore
import leaveApplied, {
  LeaveAppliedState
} from 'app/entities/leave-applied/leave-applied.reducer';
// prettier-ignore
import claimType, {
  ClaimTypeState
} from 'app/entities/claim-type/claim-type.reducer';
// prettier-ignore
import claim, {
  ClaimState
} from 'app/entities/claim/claim.reducer';
// prettier-ignore
import claimStatus, {
  ClaimStatusState
} from 'app/entities/claim-status/claim-status.reducer';
// prettier-ignore
import claimItem, {
  ClaimItemState
} from 'app/entities/claim-item/claim-item.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly department: DepartmentHrmState;
  readonly employee: EmployeeHrmState;
  readonly accessCard: AccessCardHrmState;
  readonly position: PositionHrmState;
  readonly jobDescription: JobDescriptionHrmState;
  readonly festival: FestivalHrmState;
  readonly leaveApplication: LeaveApplicationHrmState;
  readonly leaveStatus: LeaveStatusHrmState;
  readonly leaveType: LeaveTypeHrmState;
  readonly leaveEntitlement: LeaveEntitlementHrmState;
  readonly attendance: AttendanceHrmState;
  readonly weeklyAttendance: WeeklyAttendanceHrmState;
  readonly sourceDocument: SourceDocumentHrmState;
  readonly room: RoomHrmState;
  readonly door: DoorHrmState;
  readonly cardReader: CardReaderHrmState;
  readonly doorAccess: DoorAccessHrmState;
  readonly outOfOfficeReport: OutOfOfficeReportHrmState;
  readonly contactType: ContactTypeHrmState;
  readonly contact: ContactHrmState;
  readonly addressType: AddressTypeHrmState;
  readonly employeeAddress: EmployeeAddressHrmState;
  readonly companyAddress: CompanyAddressHrmState;
  readonly company: CompanyHrmState;
  readonly companyType: CompanyTypeHrmState;
  readonly leaveApplied: LeaveAppliedState;
  readonly claimType: ClaimTypeState;
  readonly claim: ClaimState;
  readonly claimStatus: ClaimStatusState;
  readonly claimItem: ClaimItemState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  department,
  employee,
  accessCard,
  position,
  jobDescription,
  festival,
  leaveApplication,
  leaveStatus,
  leaveType,
  leaveEntitlement,
  attendance,
  weeklyAttendance,
  sourceDocument,
  room,
  door,
  cardReader,
  doorAccess,
  outOfOfficeReport,
  contactType,
  contact,
  addressType,
  employeeAddress,
  companyAddress,
  company,
  companyType,
  leaveApplied,
  claimType,
  claim,
  claimStatus,
  claimItem,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar
});

export default rootReducer;
