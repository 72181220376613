import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './leave-application-hrm.reducer';
import { ILeaveApplicationHrm } from 'app/shared/model/leave-application-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ILeaveApplicationHrmDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class LeaveApplicationHrmDetail extends React.Component<ILeaveApplicationHrmDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { leaveApplicationEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="acelsysHrmApp.leaveApplication.detail.title">LeaveApplication</Translate> [<b>
              {leaveApplicationEntity.id}
            </b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="applyDate">
                <Translate contentKey="acelsysHrmApp.leaveApplication.applyDate">Apply Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={leaveApplicationEntity.applyDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="startDate">
                <Translate contentKey="acelsysHrmApp.leaveApplication.startDate">Start Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={leaveApplicationEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="endDate">
                <Translate contentKey="acelsysHrmApp.leaveApplication.endDate">End Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={leaveApplicationEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="resumeWorkDate">
                <Translate contentKey="acelsysHrmApp.leaveApplication.resumeWorkDate">Resume Work Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={leaveApplicationEntity.resumeWorkDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="appliedDays">
                <Translate contentKey="acelsysHrmApp.leaveApplication.appliedDays">Applied Days</Translate>
              </span>
            </dt>
            <dd>{leaveApplicationEntity.appliedDays}</dd>
            <dt>
              <span id="emergencyContact">
                <Translate contentKey="acelsysHrmApp.leaveApplication.emergencyContact">Emergency Contact</Translate>
              </span>
            </dt>
            <dd>{leaveApplicationEntity.emergencyContact}</dd>
            <dt>
              <span id="processedDate">
                <Translate contentKey="acelsysHrmApp.leaveApplication.processedDate">Processed Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={leaveApplicationEntity.processedDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="approvedDays">
                <Translate contentKey="acelsysHrmApp.leaveApplication.approvedDays">Approved Days</Translate>
              </span>
            </dt>
            <dd>{leaveApplicationEntity.approvedDays}</dd>
            <dt>
              <span id="reason">
                <Translate contentKey="acelsysHrmApp.leaveApplication.reason">Reason</Translate>
              </span>
            </dt>
            <dd>{leaveApplicationEntity.reason}</dd>
            <dt>
              <Translate contentKey="acelsysHrmApp.leaveApplication.leaveType">Leave Type</Translate>
            </dt>
            <dd>{leaveApplicationEntity.leaveTypeName ? leaveApplicationEntity.leaveTypeName : ''}</dd>
            <dt>
              <Translate contentKey="acelsysHrmApp.leaveApplication.employee">Employee</Translate>
            </dt>
            <dd>{leaveApplicationEntity.employeeLegalName ? leaveApplicationEntity.employeeLegalName : ''}</dd>
            <dt>
              <Translate contentKey="acelsysHrmApp.leaveApplication.status">Status</Translate>
            </dt>
            <dd>{leaveApplicationEntity.statusName ? leaveApplicationEntity.statusName : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/leave-application-hrm" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>&nbsp;
          <Button tag={Link} to={`/entity/leave-application-hrm/${leaveApplicationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ leaveApplication }: IRootState) => ({
  leaveApplicationEntity: leaveApplication.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveApplicationHrmDetail);
