export interface ICardReaderHrm {
  id?: number;
  name?: string;
  inUse?: boolean;
  entrance?: boolean;
  doorName?: string;
  doorId?: number;
}

export const defaultValue: Readonly<ICardReaderHrm> = {
  inUse: false,
  entrance: false
};
