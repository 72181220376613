import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ILeaveApplicationHrm } from 'app/shared/model/leave-application-hrm.model';
import { getEntities as getLeaveApplications } from 'app/entities/leave-application-hrm/leave-application-hrm.reducer';
import { IOutOfOfficeReportHrm } from 'app/shared/model/out-of-office-report-hrm.model';
import { getEntities as getOutOfOfficeReports } from 'app/entities/out-of-office-report-hrm/out-of-office-report-hrm.reducer';
import { getEntity, updateEntity, createEntity, reset } from './source-document-hrm.reducer';
import { ISourceDocumentHrm } from 'app/shared/model/source-document-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISourceDocumentHrmUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ISourceDocumentHrmUpdateState {
  isNew: boolean;
  leaveApplicationId: string;
  reportId: string;
}

export class SourceDocumentHrmUpdate extends React.Component<ISourceDocumentHrmUpdateProps, ISourceDocumentHrmUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      leaveApplicationId: '0',
      reportId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getLeaveApplications();
    this.props.getOutOfOfficeReports();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { sourceDocumentEntity } = this.props;
      const entity = {
        ...sourceDocumentEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/source-document-hrm');
  };

  render() {
    const { sourceDocumentEntity, leaveApplications, outOfOfficeReports, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="acelsysHrmApp.sourceDocument.home.createOrEditLabel">
              <Translate contentKey="acelsysHrmApp.sourceDocument.home.createOrEditLabel">Create or edit a SourceDocument</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : sourceDocumentEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="source-document-hrm-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="pathLabel" for="path">
                    <Translate contentKey="acelsysHrmApp.sourceDocument.path">Path</Translate>
                  </Label>
                  <AvField id="source-document-hrm-path" type="text" name="path" />
                </AvGroup>
                <AvGroup>
                  <Label id="docNoLabel" for="docNo">
                    <Translate contentKey="acelsysHrmApp.sourceDocument.docNo">Doc No</Translate>
                  </Label>
                  <AvField
                    id="source-document-hrm-docNo"
                    type="text"
                    name="docNo"
                    validate={{
                      maxLength: { value: 30, errorMessage: translate('entity.validation.maxlength', { max: 30 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="notesLabel" for="notes">
                    <Translate contentKey="acelsysHrmApp.sourceDocument.notes">Notes</Translate>
                  </Label>
                  <AvField id="source-document-hrm-notes" type="text" name="notes" />
                </AvGroup>
                <AvGroup>
                  <Label for="leaveApplication.id">
                    <Translate contentKey="acelsysHrmApp.sourceDocument.leaveApplication">Leave Application</Translate>
                  </Label>
                  <AvInput id="source-document-hrm-leaveApplication" type="select" className="form-control" name="leaveApplicationId">
                    <option value="" key="0" />
                    {leaveApplications
                      ? leaveApplications.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="report.id">
                    <Translate contentKey="acelsysHrmApp.sourceDocument.report">Report</Translate>
                  </Label>
                  <AvInput id="source-document-hrm-report" type="select" className="form-control" name="reportId">
                    <option value="" key="0" />
                    {outOfOfficeReports
                      ? outOfOfficeReports.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/source-document-hrm" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />&nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />&nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  leaveApplications: storeState.leaveApplication.entities,
  outOfOfficeReports: storeState.outOfOfficeReport.entities,
  sourceDocumentEntity: storeState.sourceDocument.entity,
  loading: storeState.sourceDocument.loading,
  updating: storeState.sourceDocument.updating,
  updateSuccess: storeState.sourceDocument.updateSuccess
});

const mapDispatchToProps = {
  getLeaveApplications,
  getOutOfOfficeReports,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceDocumentHrmUpdate);
