import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAccessCardHrm, defaultValue } from 'app/shared/model/access-card-hrm.model';

export const ACTION_TYPES = {
  FETCH_ACCESSCARD_LIST: 'accessCard/FETCH_ACCESSCARD_LIST',
  FETCH_ACCESSCARD: 'accessCard/FETCH_ACCESSCARD',
  CREATE_ACCESSCARD: 'accessCard/CREATE_ACCESSCARD',
  UPDATE_ACCESSCARD: 'accessCard/UPDATE_ACCESSCARD',
  DELETE_ACCESSCARD: 'accessCard/DELETE_ACCESSCARD',
  RESET: 'accessCard/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAccessCardHrm>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type AccessCardHrmState = Readonly<typeof initialState>;

// Reducer

export default (state: AccessCardHrmState = initialState, action): AccessCardHrmState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ACCESSCARD_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ACCESSCARD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ACCESSCARD):
    case REQUEST(ACTION_TYPES.UPDATE_ACCESSCARD):
    case REQUEST(ACTION_TYPES.DELETE_ACCESSCARD):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ACCESSCARD_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ACCESSCARD):
    case FAILURE(ACTION_TYPES.CREATE_ACCESSCARD):
    case FAILURE(ACTION_TYPES.UPDATE_ACCESSCARD):
    case FAILURE(ACTION_TYPES.DELETE_ACCESSCARD):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACCESSCARD_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACCESSCARD):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACCESSCARD):
    case SUCCESS(ACTION_TYPES.UPDATE_ACCESSCARD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ACCESSCARD):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/access-cards';

// Actions

export const getEntities: ICrudGetAllAction<IAccessCardHrm> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ACCESSCARD_LIST,
    payload: axios.get<IAccessCardHrm>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IAccessCardHrm> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ACCESSCARD,
    payload: axios.get<IAccessCardHrm>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IAccessCardHrm> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ACCESSCARD,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IAccessCardHrm> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ACCESSCARD,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAccessCardHrm> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ACCESSCARD,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
