import { Moment } from 'moment';

export interface IAttendanceHrm {
  id?: number;
  timeIn?: Moment;
  timeOut?: Moment;
  workHours?: number;
  remarks?: string;
  workDate?: Moment;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<IAttendanceHrm> = {};
