export interface IClaimStatus {
  id?: number;
  name?: string;
  description?: string;
  code?: string;
  active?: boolean;
}

export const defaultValue: Readonly<IClaimStatus> = {
  active: false
};
