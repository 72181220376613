import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './out-of-office-report-hrm.reducer';
import { IOutOfOfficeReportHrm } from 'app/shared/model/out-of-office-report-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IOutOfOfficeReportHrmDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class OutOfOfficeReportHrmDetail extends React.Component<IOutOfOfficeReportHrmDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { outOfOfficeReportEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="acelsysHrmApp.outOfOfficeReport.detail.title">OutOfOfficeReport</Translate> [<b>
              {outOfOfficeReportEntity.id}
            </b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="reportDateTime">
                <Translate contentKey="acelsysHrmApp.outOfOfficeReport.reportDateTime">Report Date Time</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={outOfOfficeReportEntity.reportDateTime} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="location">
                <Translate contentKey="acelsysHrmApp.outOfOfficeReport.location">Location</Translate>
              </span>
            </dt>
            <dd>{outOfOfficeReportEntity.location}</dd>
            <dt>
              <span id="geoLocation">
                <Translate contentKey="acelsysHrmApp.outOfOfficeReport.geoLocation">Geo Location</Translate>
              </span>
            </dt>
            <dd>{outOfOfficeReportEntity.geoLocation}</dd>
            <dt>
              <span id="notes">
                <Translate contentKey="acelsysHrmApp.outOfOfficeReport.notes">Notes</Translate>
              </span>
            </dt>
            <dd>{outOfOfficeReportEntity.notes}</dd>
            <dt>
              <span id="inward">
                <Translate contentKey="acelsysHrmApp.outOfOfficeReport.inward">Inward</Translate>
              </span>
            </dt>
            <dd>{outOfOfficeReportEntity.inward ? 'true' : 'false'}</dd>
            <dt>
              <Translate contentKey="acelsysHrmApp.outOfOfficeReport.employee">Employee</Translate>
            </dt>
            <dd>{outOfOfficeReportEntity.employeeLegalName ? outOfOfficeReportEntity.employeeLegalName : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/out-of-office-report-hrm" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>&nbsp;
          <Button tag={Link} to={`/entity/out-of-office-report-hrm/${outOfOfficeReportEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ outOfOfficeReport }: IRootState) => ({
  outOfOfficeReportEntity: outOfOfficeReport.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutOfOfficeReportHrmDetail);
