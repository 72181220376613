import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './position-hrm.reducer';
import { IPositionHrm } from 'app/shared/model/position-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IPositionHrmDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class PositionHrmDetail extends React.Component<IPositionHrmDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { positionEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="acelsysHrmApp.position.detail.title">Position</Translate> [<b>{positionEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="title">
                <Translate contentKey="acelsysHrmApp.position.title">Title</Translate>
              </span>
            </dt>
            <dd>{positionEntity.title}</dd>
            <dt>
              <span id="minSalary">
                <Translate contentKey="acelsysHrmApp.position.minSalary">Min Salary</Translate>
              </span>
            </dt>
            <dd>{positionEntity.minSalary}</dd>
            <dt>
              <span id="maxSalary">
                <Translate contentKey="acelsysHrmApp.position.maxSalary">Max Salary</Translate>
              </span>
            </dt>
            <dd>{positionEntity.maxSalary}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="acelsysHrmApp.position.description">Description</Translate>
              </span>
            </dt>
            <dd>{positionEntity.description}</dd>
            <dt>
              <Translate contentKey="acelsysHrmApp.position.employee">Employee</Translate>
            </dt>
            <dd>{positionEntity.employeeLegalName ? positionEntity.employeeLegalName : ''}</dd>
          </dl>
          <Button tag={Link} to="/entity/position-hrm" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>&nbsp;
          <Button tag={Link} to={`/entity/position-hrm/${positionEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ position }: IRootState) => ({
  positionEntity: position.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionHrmDetail);
