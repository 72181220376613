import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClaimStatus from './claim-status';
import ClaimStatusDetail from './claim-status-detail';
import ClaimStatusUpdate from './claim-status-update';
import ClaimStatusDeleteDialog from './claim-status-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClaimStatusUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClaimStatusUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClaimStatusDetail} />
      <ErrorBoundaryRoute path={match.url} component={ClaimStatus} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ClaimStatusDeleteDialog} />
  </>
);

export default Routes;
