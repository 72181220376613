import React from 'react';
import { Switch } from 'react-router-dom';

// tslint:disable-next-line:no-unused-variable
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DepartmentHrm from './department-hrm';
import EmployeeHrm from './employee-hrm';
import AccessCardHrm from './access-card-hrm';
import PositionHrm from './position-hrm';
import JobDescriptionHrm from './job-description-hrm';
import FestivalHrm from './festival-hrm';
import LeaveApplicationHrm from './leave-application-hrm';
import LeaveStatusHrm from './leave-status-hrm';
import LeaveTypeHrm from './leave-type-hrm';
import LeaveEntitlementHrm from './leave-entitlement-hrm';
import AttendanceHrm from './attendance-hrm';
import WeeklyAttendanceHrm from './weekly-attendance-hrm';
import SourceDocumentHrm from './source-document-hrm';
import RoomHrm from './room-hrm';
import DoorHrm from './door-hrm';
import CardReaderHrm from './card-reader-hrm';
import DoorAccessHrm from './door-access-hrm';
import OutOfOfficeReportHrm from './out-of-office-report-hrm';
import ContactTypeHrm from './contact-type-hrm';
import ContactHrm from './contact-hrm';
import AddressTypeHrm from './address-type-hrm';
import EmployeeAddressHrm from './employee-address-hrm';
import CompanyAddressHrm from './company-address-hrm';
import CompanyHrm from './company-hrm';
import CompanyTypeHrm from './company-type-hrm';
import LeaveApplied from './leave-applied';
import ClaimType from './claim-type';
import Claim from './claim';
import ClaimStatus from './claim-status';
import ClaimItem from './claim-item';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}/department-hrm`} component={DepartmentHrm} />
      <ErrorBoundaryRoute path={`${match.url}/employee-hrm`} component={EmployeeHrm} />
      <ErrorBoundaryRoute path={`${match.url}/access-card-hrm`} component={AccessCardHrm} />
      <ErrorBoundaryRoute path={`${match.url}/position-hrm`} component={PositionHrm} />
      <ErrorBoundaryRoute path={`${match.url}/job-description-hrm`} component={JobDescriptionHrm} />
      <ErrorBoundaryRoute path={`${match.url}/festival-hrm`} component={FestivalHrm} />
      <ErrorBoundaryRoute path={`${match.url}/leave-application-hrm`} component={LeaveApplicationHrm} />
      <ErrorBoundaryRoute path={`${match.url}/leave-status-hrm`} component={LeaveStatusHrm} />
      <ErrorBoundaryRoute path={`${match.url}/leave-type-hrm`} component={LeaveTypeHrm} />
      <ErrorBoundaryRoute path={`${match.url}/leave-entitlement-hrm`} component={LeaveEntitlementHrm} />
      <ErrorBoundaryRoute path={`${match.url}/attendance-hrm`} component={AttendanceHrm} />
      <ErrorBoundaryRoute path={`${match.url}/weekly-attendance-hrm`} component={WeeklyAttendanceHrm} />
      <ErrorBoundaryRoute path={`${match.url}/source-document-hrm`} component={SourceDocumentHrm} />
      <ErrorBoundaryRoute path={`${match.url}/room-hrm`} component={RoomHrm} />
      <ErrorBoundaryRoute path={`${match.url}/door-hrm`} component={DoorHrm} />
      <ErrorBoundaryRoute path={`${match.url}/card-reader-hrm`} component={CardReaderHrm} />
      <ErrorBoundaryRoute path={`${match.url}/door-access-hrm`} component={DoorAccessHrm} />
      <ErrorBoundaryRoute path={`${match.url}/out-of-office-report-hrm`} component={OutOfOfficeReportHrm} />
      <ErrorBoundaryRoute path={`${match.url}/contact-type-hrm`} component={ContactTypeHrm} />
      <ErrorBoundaryRoute path={`${match.url}/contact-hrm`} component={ContactHrm} />
      <ErrorBoundaryRoute path={`${match.url}/address-type-hrm`} component={AddressTypeHrm} />
      <ErrorBoundaryRoute path={`${match.url}/employee-address-hrm`} component={EmployeeAddressHrm} />
      <ErrorBoundaryRoute path={`${match.url}/company-address-hrm`} component={CompanyAddressHrm} />
      <ErrorBoundaryRoute path={`${match.url}/company-hrm`} component={CompanyHrm} />
      <ErrorBoundaryRoute path={`${match.url}/company-type-hrm`} component={CompanyTypeHrm} />
      <ErrorBoundaryRoute path={`${match.url}/leave-applied`} component={LeaveApplied} />
      <ErrorBoundaryRoute path={`${match.url}/claim-type`} component={ClaimType} />
      <ErrorBoundaryRoute path={`${match.url}/claim`} component={Claim} />
      <ErrorBoundaryRoute path={`${match.url}/claim-status`} component={ClaimStatus} />
      <ErrorBoundaryRoute path={`${match.url}/claim-item`} component={ClaimItem} />
      {/* jhipster-needle-add-route-path - JHipster will routes here */}
    </Switch>
  </div>
);

export default Routes;
