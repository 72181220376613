import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IEmployeeHrm } from 'app/shared/model/employee-hrm.model';
import { getEntities as getEmployees } from 'app/entities/employee-hrm/employee-hrm.reducer';
import { IClaimType } from 'app/shared/model/claim-type.model';
import { getEntities as getClaimTypes } from 'app/entities/claim-type/claim-type.reducer';
import { IClaimStatus } from 'app/shared/model/claim-status.model';
import { getEntities as getClaimStatuses } from 'app/entities/claim-status/claim-status.reducer';
import { getEntity, updateEntity, createEntity, reset } from './claim.reducer';
import { IClaim } from 'app/shared/model/claim.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IClaimUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IClaimUpdateState {
  isNew: boolean;
  employeeId: string;
  claimTypeId: string;
  claimStatusId: string;
}

export class ClaimUpdate extends React.Component<IClaimUpdateProps, IClaimUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      employeeId: '0',
      claimTypeId: '0',
      claimStatusId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getEmployees();
    this.props.getClaimTypes();
    this.props.getClaimStatuses();
  }

  saveEntity = (event, errors, values) => {
    values.claimDate = new Date(values.claimDate);
    values.approveDate = new Date(values.approveDate);
    values.disperseDate = new Date(values.disperseDate);

    if (errors.length === 0) {
      const { claimEntity } = this.props;
      const entity = {
        ...claimEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/claim');
  };

  render() {
    const { claimEntity, employees, claimTypes, claimStatuses, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="acelsysHrmApp.claim.home.createOrEditLabel">
              <Translate contentKey="acelsysHrmApp.claim.home.createOrEditLabel">Create or edit a Claim</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : claimEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="claim-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="referenceNoLabel" for="referenceNo">
                    <Translate contentKey="acelsysHrmApp.claim.referenceNo">Reference No</Translate>
                  </Label>
                  <AvField id="claim-referenceNo" type="text" name="referenceNo" />
                </AvGroup>
                <AvGroup>
                  <Label id="claimDateLabel" for="claimDate">
                    <Translate contentKey="acelsysHrmApp.claim.claimDate">Claim Date</Translate>
                  </Label>
                  <AvInput
                    id="claim-claimDate"
                    type="datetime-local"
                    className="form-control"
                    name="claimDate"
                    value={isNew ? null : convertDateTimeFromServer(this.props.claimEntity.claimDate)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="totalAmountLabel" for="totalAmount">
                    <Translate contentKey="acelsysHrmApp.claim.totalAmount">Total Amount</Translate>
                  </Label>
                  <AvField id="claim-totalAmount" type="text" name="totalAmount" />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="description">
                    <Translate contentKey="acelsysHrmApp.claim.description">Description</Translate>
                  </Label>
                  <AvField id="claim-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label id="approveDateLabel" for="approveDate">
                    <Translate contentKey="acelsysHrmApp.claim.approveDate">Approve Date</Translate>
                  </Label>
                  <AvInput
                    id="claim-approveDate"
                    type="datetime-local"
                    className="form-control"
                    name="approveDate"
                    value={isNew ? null : convertDateTimeFromServer(this.props.claimEntity.approveDate)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="disperseDateLabel" for="disperseDate">
                    <Translate contentKey="acelsysHrmApp.claim.disperseDate">Disperse Date</Translate>
                  </Label>
                  <AvInput
                    id="claim-disperseDate"
                    type="datetime-local"
                    className="form-control"
                    name="disperseDate"
                    value={isNew ? null : convertDateTimeFromServer(this.props.claimEntity.disperseDate)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="employee.legalName">
                    <Translate contentKey="acelsysHrmApp.claim.employee">Employee</Translate>
                  </Label>
                  <AvInput id="claim-employee" type="select" className="form-control" name="employeeId">
                    <option value="" key="0" />
                    {employees
                      ? employees.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.legalName}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="claimType.name">
                    <Translate contentKey="acelsysHrmApp.claim.claimType">Claim Type</Translate>
                  </Label>
                  <AvInput id="claim-claimType" type="select" className="form-control" name="claimTypeId">
                    <option value="" key="0" />
                    {claimTypes
                      ? claimTypes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="claimStatus.name">
                    <Translate contentKey="acelsysHrmApp.claim.claimStatus">Claim Status</Translate>
                  </Label>
                  <AvInput id="claim-claimStatus" type="select" className="form-control" name="claimStatusId">
                    <option value="" key="0" />
                    {claimStatuses
                      ? claimStatuses.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/claim" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />&nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />&nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  employees: storeState.employee.entities,
  claimTypes: storeState.claimType.entities,
  claimStatuses: storeState.claimStatus.entities,
  claimEntity: storeState.claim.entity,
  loading: storeState.claim.loading,
  updating: storeState.claim.updating,
  updateSuccess: storeState.claim.updateSuccess
});

const mapDispatchToProps = {
  getEmployees,
  getClaimTypes,
  getClaimStatuses,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimUpdate);
