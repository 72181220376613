import React from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from '../header-components';

const hrmMenuItemsAuthenticated = (
  <>
    <DropdownItem tag={Link} to="/hrm/attendance">
      <FontAwesomeIcon icon="wrench" fixedWidth /> <Translate contentKey="global.menu.hrm.attendance">Attendance</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/hrm/festivals">
      <FontAwesomeIcon icon="sign-out-alt" fixedWidth /> <Translate contentKey="global.menu.hrm.festivals">Festivals</Translate>
    </DropdownItem>
  </>
);

const hrmMenuItems = (
  <>
    <DropdownItem id="attendance-item" tag={Link} to="/login">
      <FontAwesomeIcon icon="sign-in-alt" fixedWidth /> <Translate contentKey="global.menu.hrm.attendance">Attendance</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/register">
      <FontAwesomeIcon icon="sign-in-alt" fixedWidth /> <Translate contentKey="global.menu.hrm.claims">Claims</Translate>
    </DropdownItem>
  </>
);

export const HrmMenu = ({ isAuthenticated = false }) => (
  <NavDropdown icon="user" name={translate('global.menu.hrm.main')} id="hrm-menu">
    {hrmMenuItemsAuthenticated}
  </NavDropdown>
);

export default HrmMenu;
