export interface ISourceDocumentHrm {
  id?: number;
  path?: string;
  docNo?: string;
  notes?: string;
  leaveApplicationId?: number;
  reportId?: number;
}

export const defaultValue: Readonly<ISourceDocumentHrm> = {};
