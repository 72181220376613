import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ILeaveStatusHrm, defaultValue } from 'app/shared/model/leave-status-hrm.model';

export const ACTION_TYPES = {
  FETCH_LEAVESTATUS_LIST: 'leaveStatus/FETCH_LEAVESTATUS_LIST',
  FETCH_LEAVESTATUS: 'leaveStatus/FETCH_LEAVESTATUS',
  CREATE_LEAVESTATUS: 'leaveStatus/CREATE_LEAVESTATUS',
  UPDATE_LEAVESTATUS: 'leaveStatus/UPDATE_LEAVESTATUS',
  DELETE_LEAVESTATUS: 'leaveStatus/DELETE_LEAVESTATUS',
  RESET: 'leaveStatus/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ILeaveStatusHrm>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type LeaveStatusHrmState = Readonly<typeof initialState>;

// Reducer

export default (state: LeaveStatusHrmState = initialState, action): LeaveStatusHrmState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LEAVESTATUS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_LEAVESTATUS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_LEAVESTATUS):
    case REQUEST(ACTION_TYPES.UPDATE_LEAVESTATUS):
    case REQUEST(ACTION_TYPES.DELETE_LEAVESTATUS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_LEAVESTATUS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_LEAVESTATUS):
    case FAILURE(ACTION_TYPES.CREATE_LEAVESTATUS):
    case FAILURE(ACTION_TYPES.UPDATE_LEAVESTATUS):
    case FAILURE(ACTION_TYPES.DELETE_LEAVESTATUS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_LEAVESTATUS_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_LEAVESTATUS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_LEAVESTATUS):
    case SUCCESS(ACTION_TYPES.UPDATE_LEAVESTATUS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_LEAVESTATUS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/leave-statuses';

// Actions

export const getEntities: ICrudGetAllAction<ILeaveStatusHrm> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_LEAVESTATUS_LIST,
    payload: axios.get<ILeaveStatusHrm>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ILeaveStatusHrm> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_LEAVESTATUS,
    payload: axios.get<ILeaveStatusHrm>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ILeaveStatusHrm> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_LEAVESTATUS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ILeaveStatusHrm> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LEAVESTATUS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ILeaveStatusHrm> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_LEAVESTATUS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
