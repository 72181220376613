import { Moment } from 'moment';

export interface IAccessCardHrm {
  id?: number;
  cardId?: string;
  active?: boolean;
  createDate?: Moment;
  employeeLegalName?: string;
  employeeId?: number;
}

export const defaultValue: Readonly<IAccessCardHrm> = {
  active: false
};
