import { Moment } from 'moment';

export interface IFestivalHrm {
  id?: number;
  name?: string;
  description?: string;
  startDate?: Moment;
  length?: number;
}

export const defaultValue: Readonly<IFestivalHrm> = {};
