import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import {
  Translate,
  ICrudGetAllAction,
  TextFormat,
  getSortState,
  IPaginationBaseState,
  getPaginationItemsNumber,
  JhiPagination
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './leave-application-hrm.reducer';
import { ILeaveApplicationHrm } from 'app/shared/model/leave-application-hrm.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface ILeaveApplicationHrmProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ILeaveApplicationHrmState = IPaginationBaseState;

export class LeaveApplicationHrm extends React.Component<ILeaveApplicationHrmProps, ILeaveApplicationHrmState> {
  state: ILeaveApplicationHrmState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { leaveApplicationList, match, totalItems } = this.props;
    return (
      <div>
        <h2 id="leave-application-hrm-heading">
          <Translate contentKey="acelsysHrmApp.leaveApplication.home.title">Leave Applications</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />&nbsp;
            <Translate contentKey="acelsysHrmApp.leaveApplication.home.createLabel">Create new Leave Application</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={this.sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('applyDate')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.applyDate">Apply Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('startDate')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.startDate">Start Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('endDate')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.endDate">End Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('resumeWorkDate')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.resumeWorkDate">Resume Work Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('appliedDays')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.appliedDays">Applied Days</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('emergencyContact')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.emergencyContact">Emergency Contact</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('processedDate')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.processedDate">Processed Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('approvedDays')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.approvedDays">Approved Days</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('reason')}>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.reason">Reason</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.leaveType">Leave Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="acelsysHrmApp.leaveApplication.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {leaveApplicationList.map((leaveApplication, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${leaveApplication.id}`} color="link" size="sm">
                      {leaveApplication.id}
                    </Button>
                  </td>
                  <td>
                    <TextFormat type="date" value={leaveApplication.applyDate} format={APP_DATE_FORMAT} />
                  </td>
                  <td>
                    <TextFormat type="date" value={leaveApplication.startDate} format={APP_LOCAL_DATE_FORMAT} />
                  </td>
                  <td>
                    <TextFormat type="date" value={leaveApplication.endDate} format={APP_LOCAL_DATE_FORMAT} />
                  </td>
                  <td>
                    <TextFormat type="date" value={leaveApplication.resumeWorkDate} format={APP_LOCAL_DATE_FORMAT} />
                  </td>
                  <td>{leaveApplication.appliedDays}</td>
                  <td>{leaveApplication.emergencyContact}</td>
                  <td>
                    <TextFormat type="date" value={leaveApplication.processedDate} format={APP_DATE_FORMAT} />
                  </td>
                  <td>{leaveApplication.approvedDays}</td>
                  <td>{leaveApplication.reason}</td>
                  <td>
                    {leaveApplication.leaveTypeName ? (
                      <Link to={`leave-type-hrm/${leaveApplication.leaveTypeId}`}>{leaveApplication.leaveTypeName}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {leaveApplication.employeeLegalName ? (
                      <Link to={`employee-hrm/${leaveApplication.employeeId}`}>{leaveApplication.employeeLegalName}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {leaveApplication.statusName ? (
                      <Link to={`leave-status-hrm/${leaveApplication.statusId}`}>{leaveApplication.statusName}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${leaveApplication.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${leaveApplication.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${leaveApplication.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row className="justify-content-center">
          <JhiPagination
            items={getPaginationItemsNumber(totalItems, this.state.itemsPerPage)}
            activePage={this.state.activePage}
            onSelect={this.handlePagination}
            maxButtons={5}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ leaveApplication }: IRootState) => ({
  leaveApplicationList: leaveApplication.entities,
  totalItems: leaveApplication.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveApplicationHrm);
