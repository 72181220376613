import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PositionHrm from './position-hrm';
import PositionHrmDetail from './position-hrm-detail';
import PositionHrmUpdate from './position-hrm-update';
import PositionHrmDeleteDialog from './position-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PositionHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PositionHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PositionHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={PositionHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={PositionHrmDeleteDialog} />
  </>
);

export default Routes;
