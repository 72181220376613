import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ContactHrm from './contact-hrm';
import ContactHrmDetail from './contact-hrm-detail';
import ContactHrmUpdate from './contact-hrm-update';
import ContactHrmDeleteDialog from './contact-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ContactHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ContactHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ContactHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={ContactHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ContactHrmDeleteDialog} />
  </>
);

export default Routes;
