export interface ICompanyHrm {
  id?: number;
  name?: string;
  companyNo?: string;
  companyName?: string;
  companyId?: number;
  companyTypeName?: string;
  companyTypeId?: number;
}

export const defaultValue: Readonly<ICompanyHrm> = {};
