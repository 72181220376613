import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import LeaveApplicationHrm from './leave-application-hrm';
import LeaveApplicationHrmDetail from './leave-application-hrm-detail';
import LeaveApplicationHrmUpdate from './leave-application-hrm-update';
import LeaveApplicationHrmDeleteDialog from './leave-application-hrm-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={LeaveApplicationHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={LeaveApplicationHrmUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={LeaveApplicationHrmDetail} />
      <ErrorBoundaryRoute path={match.url} component={LeaveApplicationHrm} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={LeaveApplicationHrmDeleteDialog} />
  </>
);

export default Routes;
