import { Moment } from 'moment';

export interface ILeaveApplicationHrm {
  id?: number;
  applyDate?: Moment;
  startDate?: Moment;
  endDate?: Moment;
  resumeWorkDate?: Moment;
  appliedDays?: number;
  emergencyContact?: string;
  processedDate?: Moment;
  approvedDays?: number;
  reason?: string;
  leaveTypeName?: string;
  leaveTypeId?: number;
  employeeLegalName?: string;
  employeeId?: number;
  statusName?: string;
  statusId?: number;
}

export const defaultValue: Readonly<ILeaveApplicationHrm> = {};
